<template>
  <div class="pageFooterW">
    <div class="pageFooter">
      客服热线：15738786666邮箱：1985786934@qq.com <br />
      版权所有：河南省颍山红农业开发有限公司<br />
      网站备案/许可证号：豫ICP备2021005134号-1`<br />
    </div>
  </div>
</template>

<script>
export default {
  name: "pageFooter",
  data() {
    return {
      activeNav: 1,
      navList: [
        {
          id: 1,
          name: "首页",
          path: "/",
        },
        {
          id: 2,
          name: "课程思政示范课程",
          path: "/modelCourse",
        },
        {
          id: 3,
          name: "优秀案例库",
          path: "/caseLibrary",
        },
        {
          id: 4,
          name: "精品在线课程",
          path: "/premiumOnlineCourses",
        },
        {
          id: 5,
          name: "新闻资讯",
          path: "/newsInformation",
        },
      ],
    };
  },
  methods: {
    goSchool() {
      window.open("https://hnzjsz.mh.chaoxing.com/");
    },

    goNav(item) {
      // console.log(item);
      this.$router.push(item.path);
    },
  },
};
</script>

<style scoped lang="less">
.pageFooterW {
  margin-top: 100px;

  width: 100%;
  background: #b23737;

  .pageFooter {
    width: 1400px;
    height: 138px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
  }
}
</style>
