import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home.vue"),
  },
  {
    path: "/productIntroduction",
    name: "productIntroduction",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/productIntroduction.vue"),
  },
  {
    path: "/brandIntroduction",
    name: "brandIntroduction",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/brandIntroduction.vue"),
  },
  {
    path: "/dailyRelease",
    name: "dailyRelease",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/dailyRelease.vue"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/aboutUs.vue"),
  },
  {
    path: "/digitalChiliPepper",
    name: "digitalChiliPepper",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/digitalChiliPepper.vue"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/aboutUs.vue"),
  },
  {
    path: "/teacherGang",
    name: "teacherGang",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/teacherGang.vue"),
  },
  {
    path: "/premiumOnlineCourses",
    name: "premiumOnlineCourses",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/premiumOnlineCourses.vue"
      ),
  },
  {
    path: "/modelCourse",
    name: "modelCourse",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/modelCourse.vue"),
  },
  {
    path: "/courseFaculty",
    name: "courseFaculty",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/courseFaculty.vue"),
  },
  {
    path: "/newsInformation",
    name: "newsInformation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsInformation.vue"),
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsDetail.vue"),
  },
  {
    path: "/teacherDetails",
    name: "teacherDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/teacherDetails.vue"),
  },
  {
    path: "/courseDetails",
    name: "courseDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/courseDetails.vue"),
  },
  {
    path: "/courseDetailsVideo",
    name: "courseDetailsVideo",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/courseDetailsVideo.vue"),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
