import Vue from "vue";
import ElementUI from "element-ui";
import App from "./App.vue";
import router from "./router";
import "element-ui/lib/theme-chalk/index.css";
import "normalize.css";
import "lib-flexible";
import {
  postRequest,
  getRequest,
  setRequest,
  baseUrl,
  websocket,
  getRequestaddress,
} from "@/api/http";
// import '@/assets/styles/common.less'
Vue.config.productionTip = false;
Vue.prototype.$baseUrl = baseUrl;
Vue.prototype.$post = postRequest;
Vue.prototype.$get = getRequest;
Vue.prototype.$getAddress = getRequestaddress;
Vue.prototype.$setRequest = setRequest;
Vue.use(ElementUI);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
