<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/pageHeader.vue";
import Footer from "@/components/pageFooter.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style>
html,
body {
  overflow-x: hidden;
}
/* .pageFooterW {
  position: fixed;
  bottom: 0;
} */
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ellipsis_multiline {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis_multiline_4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis_multiline_5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dbea;
  border-radius: 9999px;
}
</style>
