import axios from "axios";
import { Message } from "element-ui";
// import cookie from 'js-cookie'
// 接口路径

const baseUrl = "https://lajiaoweb.xnszz.com";
// const baseUrl = "http://192.168.0.163:8877";
const websocket = "";

// 创建axios实例
const service = axios.create({
  timeout: 300000, // 请求超时时间
});

// request请求拦截器
service.interceptors.request.use(
  (res) => {
    // let memberId = ""
    // let arr = document.cookie.split(';')
    // arr.forEach(item => {
    // 	if (item.indexOf('memberId=') == 0) {
    // 		memberId = item.substring(9)
    // 	}
    // })
    // console.log(memberId);
    // res.headers.Authorization = cookie.get('memberId');
    return res;
  },
  (error) => {
    Promise.reject(error);
  }
);

// request响应拦截器
service.interceptors.response.use(
  (response) => {
    let res = response.data;
    // 返回处理
    // console.log(response.config.url, response.config.method == 'get' ? response.config.params : JSON.parse(response.config.data), res);
    if (res.code == 200) {
      return res;
    } else if (res.code == 403) {
    } else {
      if (!res.msg) {
        res.msg = "请求错误";
      }
      Message.error(res.msg);
      return Promise.reject(new Error(""));
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 封装post请求
var postRequest = function (url, data = {}) {
  let sendObject = {
    url: baseUrl + url,
    method: "Post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: data,
    params: {},
  };
  // sendObject.data = JSON.stringify(data)
  return service(sendObject);
};
// 封装get请求
var getRequest = function (url, data = {}) {
  let sendObject = {
    url: baseUrl + url,
    method: "get",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    withCredentials: true,
    params: { ...data },
  };
  sendObject.data = JSON.stringify(data);
  return service(sendObject);
};
// 封装get请求
var getRequestaddress = function (url, data = {}) {
  let sendObject = {
    url: baseUrl + url,
    method: "get",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    withCredentials: true,
    params: { ...data },
    dataType: "jsonp",
  };
  sendObject.data = JSON.stringify(data);
  return service(sendObject);
};
// 封装自定义方式请求
var setRequest = function (url, data = {}, type, flag = false) {
  let sendObject = {
    url: baseUrl + url,
    method: type,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  if (type == "put") {
    sendObject.data = data;
  } else {
    sendObject.params = data;
  }
  // sendObject.data = JSON.stringify(data)
  return service(sendObject);
};
export {
  postRequest,
  getRequest,
  setRequest,
  getRequestaddress,
  baseUrl,
  websocket,
};
