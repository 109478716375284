<template>
  <div class="pageHeaderW">
    <div class="pageHeader">
      <img class="logoImg" src="../assets/header/logo.png" alt="" />

      <div class="navUl">
        <div
          :class="['navLi', activeNav == item.id ? 'activeNav' : '']"
          v-for="(item, index) in navList"
          :key="item.id"
          @click="goNav(item, index)"
        >
          {{ item.name }}

          <div class="line" v-if="activeNav == item.id"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageHeader",
  data() {
    return {
      activeNav: 1,
      navList: [
        {
          id: 1,
          name: "首页",
          path: "/",
        },
        {
          id: 2,
          name: "品牌介绍",
          path: "/brandIntroduction",
        },
        {
          id: 3,
          name: "产品介绍",
          path: "/productIntroduction",
        },
        {
          id: 4,
          name: "数字辣椒",
          path: "/digitalChiliPepper",
        },
        {
          id: 5,
          name: "日常发布",
          path: "/dailyRelease",
        },
        {
          id: 6,
          name: "行业资讯",
          path: "/newsInformation",
        },
        {
          id: 7,
          name: "关于我们",
          path: "/aboutUs",
        },
        {
          id: 8,
          name: "交易中心",
          path: "",
        },
      ],
    };
  },
  mounted() {
    console.log(this.$route);
  },
  watch: {
    $route(to) {
      console.log("Route is changing to:", to);
      this.navList.forEach((item, index) => {
        if (item.path == to.path) {
          this.activeNav = item.id;
        }
      });
      // this.route = to;
    },
  },
  methods: {
    goNav(item, index) {
      // console.log(item);
      this.activeNav = item.id;
      this.$router.push(item.path);
    },
  },
};
</script>

<style scoped lang="less">
.pageHeaderW {
  width: 100%;
  // background: url("../assets/header/headerBg.png") no-repeat;
  background-size: 100% 100%;

  .pageHeader {
    width: 1400px;
    height: 137px;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .logoImg {
      width: 234px;
      height: 104px;
      // background: url('../assets/header/logo.png') no-repeat;
      // background-size: 100% 100%;
    }

    .navUl {
      display: flex;
      align-items: center;

      .navLi {
        cursor: pointer;
        font-weight: 400;
        font-size: 18px;
        color: #333333;
        margin-right: 60px;
        position: relative;
        &:nth-last-child(1) {
          margin-right: 0;
        }

        .line {
          position: absolute;
          bottom: -13px;
          left: 50%;
          transform: translateX(-50%);
          width: 35px;
          height: 2px;
          background: #ca1420;
        }
      }

      .activeNav {
        font-weight: bold;
        font-size: 18px;
        color: #b23737;
      }
    }
  }
}
</style>
